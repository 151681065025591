import React from 'react'

import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'

import DarkVersionOne from './pages/DarkVersionOne'


export default function Router() {
  return (
    <BrowserRouter>
      <Routes>         
        <Route exact path="/" element={<DarkVersionOne />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  )
}
