import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { tns } from 'tiny-slider/src/tiny-slider';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { bg01, office, sbnFlowchart, token, liquidity, growth, funding, sbnLogo } from '../../components/imageImport';
import { FiMail } from 'react-icons/fi'
import ReCAPTCHA from 'react-google-recaptcha';
import { encode } from 'punycode';

const webLink = "https://app.sbn.finance/"




function fadeIn( id) {
  var fade = document.getElementById(id);
  var opacity = 0;
  var intervalID = setInterval(function () {
    if (opacity < 1) {
      opacity = opacity + 0.5
      fade.style.opacity = opacity;
    } else {
      clearInterval(intervalID);
    }
  }, 200);
}

const handleNewsletter = async (e) => {
  try {
    const baseUrl = process.env.REACT_APP_AWS_API;
    const email = document.getElementById("emailsubscribe").value;
    console.log(email);
    const response = await fetch(`${baseUrl}/newsletter`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
      }),
    });
    if(response.status == 200){
      document.getElementById('success-msg').innerHTML = "<div class='alert alert-success '>Great you signed up!</div>";
      fadeIn('success-msg');
    }
  } catch (error) {
    console.error(error);
  }

};

const DarkVersionOne = () => {
  useEffect(() => {
    document.title = 'Sell Buyback NFT';
    if (document.getElementsByClassName('tiny-five-item-nav-arrow').length > 0) {
      var slider6 = tns({
        container: '.tiny-five-item-nav-arrow',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: 'bottom',
        controlsText: [
          '<i class="mdi mdi-chevron-left "></i>',
          '<i class="mdi mdi-chevron-right"></i>',
        ],
        nav: false,
        speed: 400,
        gutter: 10,
        responsive: {
          992: {
            items: 5,
          },

          767: {
            items: 3,
          },

          320: {
            items: 1,
          },
        },
      })
    }
  }, [])

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    comments: '',
    'g-recaptcha-response': '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }
  
  const handleCaptcha = (value) => {
    setFormData((prev) => ({ ...prev, 'g-recaptcha-response': value }))
  }

  async function validateForm() {
    document.getElementById("error-msg").style.opacity = 0;
    document.getElementById('error-msg').innerHTML = "";
    if (!formData.name) {
      document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please enter a Name*</div>";
      fadeIn('error-msg');
      return false;
    }
    if (!formData.email) {
      document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please enter a Email*</div>";
      fadeIn('error-msg');
      return false;
    }
    if (!formData.subject) {
      document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please enter a Subject*</div>";
      fadeIn('error-msg');
      return false;
    }
    if (!formData.comments) {
      document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please enter a Comment*</div>";
      fadeIn('error-msg');
      return false;
    }
    if (!formData['g-recaptcha-response']) {
      document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning error_message'>*Please complete the captcha*</div>";
      fadeIn('error-msg');
      return false;
    }

    try {
      const baseUrl = process.env.REACT_APP_AWS_EMAIL_API;
      const address = process.env.REACT_APP_EMAIL_ADDRESS;
      const from = document.getElementById("email").value;
      const subject = document.getElementById("subject").value;
      const message = document.getElementById("comments").value;

      const response = await fetch(`${baseUrl}`, {
        method: 'POST',
        body: JSON.stringify({
          from: from,
          to: address,
          subject: `SBN ${subject}`,
          body: message, 
        }),
      });

      if(response.status == 200){
        alert('Message Sent, we will get back to you shortly');
      }

    } catch (error) {
      console.error(error);
    }

  }

  const toggleSwitcher = () => {
    var i = document.getElementById('style-switcher')
    if (i) {
      if (i.style.left === '-189px') {
        i.style.left = '0px'
      } else {
        i.style.left = '-189px'
      }
    }
  }

  const location = useLocation()
  const getClosest = (elem, selector) => {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) { }
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;

  };
  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {

      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');
        var immediateParent = getClosest(matchingMenuItem, 'li');
        if (immediateParent) {
          immediateParent.classList.add('active');
        }

        var parent = getClosest(matchingMenuItem, '.parent-menu-item');
        if (parent) {
          parent.classList.add('active');
          var parentMenuitem = parent.querySelector('.menu-item');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }
          var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
    }
  }
  var TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate
    this.el = el
    this.loopNum = 0
    this.period = parseInt(period, 10) || 2000
    this.txt = ''
    this.tick()
    this.isDeleting = false
  }

  TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length
    var fullTxt = this.toRotate[i]
    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1)
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1)
    }
    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>'
    var that = this
    var delta = 200 - Math.random() * 100
    if (this.isDeleting) {
      delta /= 2
    }
    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period
      this.isDeleting = true
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false
      this.loopNum++
      delta = 500
    }
    setTimeout(function () {
      that.tick()
    }, delta)
  }
  const typewrite = () => {
    if (toRotate !== 'undefined')
      var elements = document.getElementsByClassName('typewrite')
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-type')
      var period = elements[i].getAttribute('data-period')
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period)
      }
    }
    // INJECT CSS
    var css = document.createElement('style')
    css.type = 'text/css'
    css.innerHTML =
      '.typewrite > .wrap { border-right: 0.08em solid transparent}'
    document.body.appendChild(css)
  }

  useEffect(() => {
    setTimeout(() => {
      if (location?.pathname === '/index-dark-rtl') {
        document.getElementById('theme-opt').href = './css/style-dark-rtl.min.css'
      } else if (location?.pathname === '/index') {
        document.getElementById('theme-opt').href = './css/style.min.css'
      } else if (location?.pathname === '/index-rtl') {
        document.getElementById('theme-opt').href = './css/style-rtl.min.css'
      } else {
        document.getElementById('theme-opt').href = './css/style.min.css'
      }
      toggleSwitcher(false)
      activateMenu()
    }, 100)
    typewrite()
  }, [location?.pathname])


  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) background-position: center center` }}
      >
        <div className="bg-overlay bg-gradient-primary opacity-8"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
              <div className="title-heading">
                <h6 className="text-light title-dark fw-normal">
                  Create, Sell, and Buy Back NFTs
                </h6>
                <h4 className="heading text-white title-dark fw-bold mb-3">
                  Join The New Era of <br />{' '}
                  <span
                    className="typewrite"
                    data-period="2000"
                    data-type='[ "Funding", "Growth", "Contracts"]'
                  ></span>
                </h4>
                <p className="text-white para-desc mb-0 mb-0">
                Bringing Investors, Teams and NFT collectors can connect, trade and provide resourcing towards sell back NFTs!
                </p>
              </div>
            </div>

            {/*end slide*/}

            <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card bg-white nft-items nft-primary img-skewed rounded-md shadow overflow-hidden mb-1 p-3">

                <div className="nft-image rounded-md mt-3 position-relative overflow-hidden shadow">
                  <img src={sbnLogo} className="img-fluid" alt="" />
                </div>

              </div>
            </div>
            {/*end slide*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Home */}

      {/* Start */}
      <section className="section">
        <div className="container">
          <div className="row align-items-end mb-4 pb-2">
            <div className="col-md-8">
              <div className="section-title">
                <h4 className="title mb-2">What is SBBN?</h4>
                <p className="text-muted mb-0">
                Sell Buyback NFTs is a service that helps a team find funding/resources for supporting their project while increasing the flexibility of investors/collectors to sell back NFT assets to liquidate positions without affecting tokenomics.
                </p>
              </div>
            </div>
            {/*end slide*/}

            <div className="col-md-4">
              <div className="text-end d-md-block d-none">
                <div class="mt-4 pt-2">
                  <a href={webLink} class="btn btn-primary rounded-md">Check it out<i class="uil uil-arrow-right"></i></a></div>
              </div>
            </div>
            {/*end slide*/}
          </div>
          {/*end row*/}

        </div>
        {/*end container*/}

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4">Use Cases</h4>
                <img src={sbnFlowchart} className="img-fluid" alt="" />
                <div className="py-4"></div>

              </div>
            </div>
            {/*end col*/}
          </div>


          {/*end row*/}



        </div>


        <div>
          <div className="container-fluid mt-100 mt-60">
            <div className="row px-0">
              <div className="bg-half-100 bg-gradient-primary">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col">
                      <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title text-white title-dark mb-4">
                          Why SBN?
                        </h4>
                        <p className="text-white para-desc mb-0 mx-auto">
                          SBBN (Sell Buy Back NFTs) is a wonderful tool made for you to grow your Token and solidify your position in the market by way of NFT sales.
                          It is a super simple concept with the exception that the original Team NFT owner buys the NFT back from the investor/collector on the last payment.  We have
                          made it so it is a simple process and help you fast track your Tokens related to projects and help grow Web3 projects with increased flexibility.
                        </p>
                      </div>
                      {/*end col*/}
                    </div>
                    {/*end row*/}

                    <div className="row">
                      <div className="col-md-6 mt-4 pt-2">
                        <div className="card p-4 rounded-md shadow bg-white">
                          <h4 className="mb-4 text-center">Liquidity</h4>
                          <div className="nft-image rounded-md mt-3 position-relative overflow-hidden shadow text-center">
                            <img src={liquidity} className="img-fluid" alt="" />
                          </div>

                          <div className="py-4"></div>
                        </div>
                      </div>
                      {/*end col*/}

                      <div className="col-md-6 mt-4 pt-2">
                        <div className="card p-4 rounded-md shadow bg-white">
                          <h4 className="mb-4 text-center">Funding</h4>
                          <div className="nft-image rounded-md mt-3 position-relative overflow-hidden shadow  text-center">
                            <img src={funding} className="img-fluid" alt="" />
                          </div>

                          <div className="py-4"></div>
                        </div>
                      </div>
                      {/*end col*/}

                      <div className="col-md-6 mt-4 pt-2">
                        <div className="card p-4 rounded-md shadow bg-white">
                          <h4 className="mb-4 text-center">Growth</h4>
                          <div className="nft-image rounded-md mt-3 position-relative overflow-hidden shadow  text-center">
                            <img src={growth} className="img-fluid" alt="" />
                          </div>

                          <div className="py-4"></div>

                        </div>
                      </div>
                      {/*end col*/}

                      <div className="col-md-6 mt-4 pt-2">
                        <div className="card p-4 rounded-md shadow bg-white">
                          <h4 className="mb-4 text-center">Tokens</h4>
                          <div className="nft-image rounded-md mt-3 position-relative overflow-hidden shadow  text-center">
                            <img src={token} className="img-fluid" alt="" />
                          </div>

                          <div className="py-4"></div>

                        </div>
                      </div>
                      <div className="col" style={{ margin: '5vh 0 0 0' }}>
                        <div className="section-title text-center mb-4 pb-2">
                          <div className="card p-4 rounded-md shadow bg-white">
                            <h4 className="title title-dark mb-4">
                              Pricing
                            </h4>
                            <p className="para-desc mb-0 mx-auto">
                              In order to bring you the best service possible we will charge a one time fee of $200 USD per contract created. All applicable charges will be made in ETH.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end col*/}

                  </div>
                  {/*end row*/}
                </div>
                {/*end container*/}
              </div>
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}


          <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
              <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                  <h4 className="title mb-4">FAQ</h4>

                  <div style={{ maxWidth: '40vw', textAlign: 'center', margin: '0 auto' }}>
                    <div className="col">
                      <div className="section-title" id="getstart">
                        <h5>Getting started</h5>
                      </div>

                      <div className="accordion mt-4 pt-2" id="buyingquestion">
                        <div className="accordion-item rounded">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button border-0 collapsed"
                              style={{ backgroundColor: "#F8F8FC" }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              How does it work ?
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse border-0 collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#buyingquestion"
                          >
                            <div className="accordion-body text-muted bg-white">
                              SBBN uses a contract system based on the ERC-721 NFT Standard.
                              After the contract is created a JSON list of items such as images is
                              uploaded and the NFTs are madre from that list.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item rounded mt-2">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button border-0 collapsed"
                              style={{ backgroundColor: "#F8F8FC" }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              How do I create a NFT
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse border-0 collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#buyingquestion"
                          >
                            <div className="accordion-body text-muted bg-white">
                              A contract must exist in the system, after that on the 'Create NFT' page you select an image,
                              set a payment period and number of payments and click the submit button. Any user can now mint NFTs from your contract.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item rounded mt-2">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button border-0 collapsed"
                              style={{ backgroundColor: "#F8F8FC" }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Are there any fees associated with SBBN
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse border-0 collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#buyingquestion"
                          >
                            <div className="accordion-body text-muted bg-white">
                              We charge a one time fee of $200 USD per contract created to the creator.
                              The minting process only requieres you to pay the associated "gas" fees from the blockchain.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item rounded mt-2">
                          <h2 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button border-0 collapsed"
                              style={{ backgroundColor: "#F8F8FC" }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              What is the sellback process ?
                            </button>
                          </h2>
                          <div
                            id="collapseFour"
                            className="accordion-collapse border-0 collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#buyingquestion"
                          >
                            <div className="accordion-body text-muted bg-white">

                              When you've paid all the payments set in the minting process you can sell
                              back the NFT to the contract owner and get in exchange tokens tied to the contract.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {/*end col*/}
            </div>


            {/*end row*/}



          </div>
          {/*end container*/}
          <div className="container mt-100 mt-60">
            <div className="row justify-content-center" style={{ maxWidth: '30vw', margin: '0px auto' }}>
              <div className="col">
                <div className="row align-items-center">
                  <div className="section-title text-center mb-4 pb-2">
                    <div className="section-title">
                      <h4 className="title mb-2">Sign up to our newsletter</h4>
                      <p className="mt-4">
                        Sign up and receive the latest news via email.
                      </p>
                      <form
                        name="submitForm"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleNewsletter()
                        }}
                      >
                        <p className="mb-0" id="success-msg"></p>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="foot-subscribe mb-3">
                              <label className="form-label">
                                Write your email{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <FiMail className="fea icon-sm icons" />
                                <input
                                  type="email"
                                  name="email"
                                  id="emailsubscribe"
                                  className="form-control ps-5 rounded"
                                  placeholder="Your email : "
                                  required
                                  style={{ height: 46 }}
                                  
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submitsubscribe"
                                name="send"
                                className="btn btn-soft-primary"
                                value="Subscribe"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="container mt-100 mt-60" id="contact">
            {/*end row*/}
            <div className="container mt-100 mt-60">
              <div className="row justify-content-center">
                <div className="col">
                  <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
                      <div className="card shadow rounded border-0">
                        <div className="card-body py-5">
                          <h4 className="card-title">Get In Touch !</h4>
                          <div className="custom-form mt-3">

                            <form name="contact" data-netlify="true" netlify-honeypot="bot-field" hidden>
                              <input type="text" name="name" />
                              <input type="email" name="email" />
                              <input type="text" name="subject" />
                              <textarea name="message"></textarea>
                            </form>

                            <form
                              method="POST"
                              name="contact"
                              data-netlify="true"
                              netlify-honeypot="bot-field"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validateForm()
                              }}
                            // id="myForm"
                            >
                              <p className="mb-0" id="error-msg"></p>
                              <div id="simple-msg"></div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Your Name <span className="text-danger">*</span>
                                    </label>
                                    <input type="hidden" name="form-name" value="contact" />
                                    <input
                                      name="name"
                                      id="name"
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Your Name :"
                                      onBlur={handleChange}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Your Email <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      name="email"
                                      id="email"
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Your Email :"
                                      onBlur={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*end col*/}

                                <div className="col-12">
                                  <div className="mb-3">
                                    <label className="form-label">Subject</label>
                                    <input
                                      name="subject"
                                      id="subject"
                                      className="form-control"
                                      placeholder="Type Subject :"
                                      onBlur={handleChange}
                                    />
                                  </div>
                                </div>
                                {/*end col*/}

                                <div className="col-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Comments <span className="text-danger">*</span>
                                    </label>
                                    <textarea
                                      name="comments"
                                      id="comments"
                                      rows="4"
                                      className="form-control"
                                      placeholder="Enter Your Message :"
                                      onBlur={handleChange}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>

                              <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', margin: '10px auto'}}>
                                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} hl='en' onChange={handleCaptcha}></ReCAPTCHA>
                              </div>     

                              <div className="row">
                                <div className="col-12">
                                  <div className="d-grid">
                                    <button
                                      type="submit"
                                      id="submit"
                                      name="send"
                                      className="btn btn-primary rounded-md"
                                      onBlur={handleChange}
                                    >
                                      Send Message
                                    </button>
                                  </div>
                                </div>
                                {/*end col*/}
                              </div>
                              {/*end row*/}
                              
                            </form>
                          </div>
                          {/*end custom-form*/}
                        </div>
                      </div>
                    </div>
                    {/*end col*/}

                    <div className="col-lg-7 col-md-6 order-1 order-md-2">
                      <div className="card border-0">
                        <div className="card-body p-0">
                          <img
                            src={office}
                            className="img-fluid d-block mx-auto"
                            style={{ maxWidth: 550 }}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/*end col*/}
                  </div>
                  <div className="container mt-100 mt-60">

                    {/*end row*/}
                  </div>
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}

              {/*end row*/}
            </div>

            {/*end row*/}
          </div>
          {/*end container*/}
        </div>
        {/*end section*/}
        {/* End */}
      </section>
      {/* footer */}
      <Footer />

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  )
}

export default DarkVersionOne
