import React from 'react'
import BackToTop from '../BackToTop'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="bg-footer">
        <div className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7 col-md-6">
                <div className='text-center'>
                  <h5 className="text-light fw-normal title-dark">
                    Check out our team over at Alio IT
                  </h5>
                  <a href='https://www.alioit.com/community'>
                    <button className="btn text-light btn-soft-secondary rounded-md " >
                      Our Team
                    </button>
                  </a>
                </div>
              </div>

              <div className="col-xl-4 col-lg-5 col-md-6 mt-4 mt-sm-0">
                <h5 className="text-light fw-normal title-dark">
                  Join SBN community
                </h5>
                <a href='https://discord.gg/fxynwYaE'>
                  <img src='https://assets-global.website-files.com/6257adef93867e50d84d30e2/62594fdd2eb6504fca0545cb_364fc8a0ee7fcebf47ca6ebd16ec12f1%20(1).svg' width="150" alt='Discord'/>
                </a>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </div>
        {/*end div*/}

        <div className="footer-py-30 footer-bar">
          <div className="container text-center">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="text-sm-start">
                  <p className="mb-0">
                    © {currentYear} Alio IT Solutions. 
                  </p>
                </div>
              </div>
              {/*end col*/}


            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </div>
      </footer>
      {/*end footer*/}

      {/* Back to top */}
      <BackToTop />
    </>
  )
}

export default Footer
